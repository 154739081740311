import { getCountry } from '@/utils/filters'

function countryName(row, constant) {
  const c = getCountry(row.country_id, constant.constants.countries)
  return c ? c.name : ''
}

function openingDay(row) {
  if (row.opening_day) {
    if (row.opening_day.length === 7) {
      return 'Cả Tuần'
    }
    return row.opening_day.map((d) => {
      if (d === 0) {
        return 'Thứ Hai'
      }
      if (d === 1) {
        return 'Thứ Ba'
      }
      if (d === 2) {
        return 'Thứ Tư'
      }
      if (d === 3) {
        return 'Thứ Năm'
      }
      if (d === 4) {
        return 'Thứ Sáu'
      }
      if (d === 5) {
        return 'Thứ Bảy'
      }
      return 'Chủ Nhật'
    }).join(', ')
  }
  return ''
}

export function convertData(row, columns, constant) {
  const data = {}
  columns.forEach(colum => {
    switch (colum.key) {
      case 'object':
        data[colum.key] = row.object ? row.object.name : ''
        break
      case 'creator':
        data[colum.key] = row.creator ? row.creator.name : ''
        break
      case 'approved':
        data[colum.key] = row.approved ? 'Đã duyệt' : 'Chưa duyệt'
        break
      case 'category_id':
        data[colum.key] = row.category ? row.category.name || '' : ''
        break
      case 'location_address':
        data[colum.key] = row.location ? row.location.address || '' : ''
        break
      case 'location_longitude':
        data[colum.key] = row.location ? row.location.longitude || '' : ''
        break
      case 'location_latitude':
        data[colum.key] = row.location ? row.location.latitude || '' : ''
        break
      case 'youtube_links':
        data[colum.key] = row.youtube_links ? row.youtube_links.join(', ') || '' : ''
        break
      case 'opening_time':
        data[colum.key] = row.opening_time ? `${row.opening_time.open_time || ''} - ${row.opening_time.close_time || ''}` : ''
        break
      case 'price_info':
        data[colum.key] = row.price_info.free ? 'Miễn Phí' : `${row.price_info.minimum || ''} - ${row.price_info.maximum || ''}`
        break
      case 'place_duration':
        data[colum.key] = row.place_duration ? `${row.place_duration.started_at || ''} - ${row.place_duration.ended_at || ''}` : ''
        break
      case 'opening_day':
        data[colum.key] = openingDay(row)
        break
      case 'country_id':
        data[colum.key] = countryName(row, constant)
        break
      case 'area_id':
        data[colum.key] = row.area_name || ''
        break
      case 'contact_info_phone':
        data[colum.key] = row.contact_info ? row.contact_info.phone || '' : ''
        break
      case 'contact_info_email':
        data[colum.key] = row.contact_info ? row.contact_info.email || '' : ''
        break
      case 'contact_info_website':
        data[colum.key] = row.contact_info ? row.contact_info.website || '' : ''
        break
      case 'tags':
        data[colum.key] = row.tags ? row.tags.map((t) => {
          const translation = t.translations.find((tr) => tr.locale === 'vi')
          return translation ? translation.name || '' : ''
        }).join(', ') : ''
        break
      default:
        data[colum.key] = `${row[colum.key] || ''}`
        break
    }
  })
  return data
}

export function JSONToFile (obj, filename) {
  const blob = new Blob([JSON.stringify(obj, null, 2)], {
    type: 'application/json'
  })
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = `${filename}.json`
  a.click()
  URL.revokeObjectURL(url)
}

