import request from '@/utils/request'
import ENDPOINT from '@/config/endpoint'

export function getArtifacts(params) {
  return request({
    url: `${ENDPOINT.LIST_ARTIFACTS}`,
    method: 'get',
    params
  })
}

export function createArtifact(data) {
  return request({
    url: `${ENDPOINT.ARTIFACT}`,
    method: 'post',
    data
  })
}

export function updateArtifact(data) {
  return request({
    url: `${ENDPOINT.ARTIFACT}`,
    method: 'patch',
    data
  })
}

export function deleteArtifact(params) {
  return request({
    url: `${ENDPOINT.ARTIFACT}`,
    method: 'delete',
    params
  })
}

export function getClassifications(params) {
  return request({
    url: ENDPOINT.CLASSIFICATION,
    method: 'get',
    params
  })
}

export function createClassification(data) {
  return request({
    url: ENDPOINT.CLASSIFICATION,
    method: 'post',
    data
  })
}

export function updateClassification(data) {
  return request({
    url: ENDPOINT.CLASSIFICATION,
    method: 'patch',
    data
  })
}

export function deleteClassification(id) {
  return request({
    url: `${ENDPOINT.CLASSIFICATION}?id=${id}`,
    method: 'delete'
  })
}
